import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import colors from "./colors"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.button`
  position: sticky;
  border-style: none;
  background: transparent;
  z-index: 1;
  padding: 10px;
`

const CardOutline = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.navy};
  border-radius: 5px;
  color: ${colors.navy};
  background-color: ${colors.lightyellow};
  box-shadow: -3px 3px ${colors.navy};
  width:150px;
  height: 100px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  &:hover,&:focus {
    color: white;
    background-color: ${colors.pink};
    border-color: white;
  }
  ${mq[0]}{
    padding: 5px;
    width: 175px;
    height: 120px;
  }
  ${mq[1]}{
    padding: 5px;
    width: 200px;
    height: 120px;
  }
  ${mq[2]}{
    padding: 5px;
    width: 250px;
    height: 145px;
  }
`

const Image = css`
  margin: 4px 4px;
  height: 50px;
  ${mq[0]}{
    width: 100%;
    margin: 0 auto;
    height: 75px;
  }
  ${mq[2]}{
    width: 100%;
    margin: 0 auto;
    height: 100px;
  }
`

const Text = styled.h4`
  padding: 10px;
  font-family: Messapia Bold;
  font-size: 90%;
`

const LinkStyle = css`
    text-decoration: none;
`

const Card = props => (
  <Wrapper tabindex="0">
    <Link to={props.link} css={LinkStyle}>
      <CardOutline>
        <Img fluid={props.image} title={props.title} css={Image} alt={props.alt} />
        <Text>{props.text}</Text>
      </CardOutline>
    </Link>
  </Wrapper>
)

export default Card
