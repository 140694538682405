import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/indexCard"
import FeatureCard from "../components/featureCard"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.main`
  position relative;
  z-index: 0;
  display: grid;
  width: 100%;
`

const BoxGrid = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  ${mq[2]} {
    margin-left: 8rem;
    grid-template-columns: repeat(3, 1fr);
    margin-top: -10rem;
    grid-gap: 0 2rem;
  }
`

const Box1 = styled.div`
  display: grid;
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  ${mq[2]} {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    margin-top: 5rem;
    margin-left: -10rem;
  }
`

const Box2 = styled.div`
  display: grid;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin-top: 2rem;
  margin-left: -5rem;
  ${mq[2]} {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-top: -2rem;
    margin-left: 0;
  }
`

const Box3 = styled.div`
  display: grid;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  ${mq[2]} {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
`

const Box4 = styled.div`
  display: grid;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  margin-left: 5rem;
  ${mq[2]} {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    margin-left: 4rem;
  }

`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      image1: file(relativePath: { eq: "front-page-images/dolphin_crop.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "front-page-images/babka-derby.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "front-page-images/resources.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      siteImage: file(relativePath: { eq: "logos/groove-cafe-color.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return(
        <Layout>
          <SEO
            title="home"
            description="Get in the groove. Get in the café."
          />
          <Wrapper>
            <BoxGrid>
              <Box1>
                <FeatureCard />
              </Box1>
              <Box2>
                <Card
                  title="go to: livestream"
                  image={data.image2.childImageSharp.fluid}
                  alt="go to: livestream"
                  text="livestream"
                  link="/live"
                />
              </Box2>
              <Box3>
                <Card
                  title="go to: calendar"
                  image={data.image1.childImageSharp.fluid}
                  alt="go to: calendar"
                  text="calendar"
                  link="/chicago-calendar"
                />
              </Box3>
              <Box4>
                <Card
                  title="go to: resources"
                  image={data.image3.childImageSharp.fluid}
                  alt="go to: resources"
                  text="resources"
                  link="/tags/resources/"
                />
              </Box4>
            </BoxGrid>
          </Wrapper>
        </Layout>
  )
}

export default IndexPage
