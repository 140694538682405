import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import colors from "./colors"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  background: transparent;
  border-style: none;
  position: sticky;
  z-index: 1;
  padding: 10px;
`

const CardOutline = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.navy};
  border-radius: 5px;
  color: ${colors.navy};
  text-align: left;
  background-color: ${colors.lightyellow};
  box-shadow: -3px 3px ${colors.navy};
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
    cursor: pointer;
  &:hover,&:focus {
    color: white;
    background-color: ${colors.pink};
    border-color: white;
  }
  ${mq[1]} {
    width: 50%;
    height: 100%;
  }
  ${mq[2]}{
    width: 250px;
    height: 100%;
  }
`

const Image = css`
  width: 100%;
  margin: 4px 4px;
  height: 100px;
  margin: 0 auto;
  ${mq[0]}{
    margin: 0 auto;
    height: 150px;
  }
  ${mq[2]}{
    height: 145px;
  }
`

const Text = styled.h4`
  display: sticky;
  font-family: Noto Sans;
  font-size: 90%;
  font-weight: 400;
  margin: 0px 0px;
`

const MobileHeader = styled.h4`
  display: flex;
  color: ${colors.darkpink};
  font-family: Messapia Bold;
  font-size: 22px;
  font-style: italic;
  margin: 0px 0px;
  ${mq[0]} {
    display: none;
  }
`

const DesktopHeader = styled.h2`
  display: none;
  ${mq[0]} {
    display: flex;
    color: ${colors.darkpink};
    font-family: Messapia Bold;
    font-size: 22px;
    font-style: italic;
    margin: 0px 0px;
  }
`

const LinkStyle = css`
    text-decoration: none;
`

const Card = () => {
  const data = useStaticQuery(graphql`
    query FeatureCardQuery {
      markdownRemark(frontmatter: {featured: {eq: true}}) {
        frontmatter {
          title
          path
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { markdownRemark } = data
  const { path, title, featuredImage } = markdownRemark.frontmatter

  return(
    <Wrapper>
      <Link to={path} css={LinkStyle}>
        <CardOutline>
          <DesktopHeader>
            Groove
            <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;du Jour
          </DesktopHeader>
          <MobileHeader>
            Groove
            <br/>&nbsp;&nbsp;&nbsp;&nbsp;du Jour
          </MobileHeader>
          <Text>{title}</Text>
          <Img fluid={featuredImage.childImageSharp.fluid} title={title} css={Image} alt={title} />
        </CardOutline>
      </Link>
    </Wrapper>
  )
}

export default Card
